import React, { useEffect, useState } from 'react';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { fetchGenerateUserReport } from '../../dashboard-actions';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { getDateFilterText } from '../../dashboard-utils';
import { DashboardPopoverLinkItem } from '../DashboardPopoverLinkItem';
import { useStylesCustom } from '../DashboardCardItem';
import { PRIMARY_COLOR } from '../../../../shared/css/theme';
import {
  useUsersLazyQuery,
  User,
  SortOrder,
} from '../../../../shared/types/generated';

export const DashboardRegisterUsersTable: React.FC = () => {
  const [total, setTotal] = useState(0);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const [{ page, pageSize }, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callUsers, { data, loading }] = useUsersLazyQuery({
    onCompleted: (d) => setTotal(d?.users?.count || 0),
  });

  const [callReport, loadingReport] = useCallAction(fetchGenerateUserReport, {
    onCompleted: ({ report }) => {
      console.log({ report });
      const worksheet = XLSX.utils.json_to_sheet(
        report.map((r) => {
          return {
            fecha: r.date
              ? moment(r.date).format('YYYY-MM-DD HH:mm')
              : moment(r.created_at).format('YYYY-MM-DD HH:mm'),
            nombre: r.name,
            correo: r.email,
            canal: r.provider !== 'SYSTEM' ? r.provider : 'Correo eletrónico',
            canal_id: r.provider_id,
            'url de registro': r.url_register,
            sex: r.sex || r.gender,
          };
        }),
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${getDateFilterText(from, to, filter)}`,
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `Reporte ${getDateFilterText(from, to, filter)}.xlsx`);
    },
  });

  useEffect(() => {
    callUsers({
      variables: {
        where: {
          gmt_created_at: {
            lte: to,
            gte: from,
          },
        },
        skip: (page - 1) * pageSize,
        take: pageSize,
        orderBy: {
          gmt_created_at: SortOrder.Desc,
        },
      },
    });
  }, [page, pageSize, callUsers, from, to]);

  const COLUMNS: TableColumnType<User>[] = [
    {
      columnName: (
        <Box display="flex" color={'#BBBBBB'} alignItems="center">
          <Typography color={'#BBBBBB'}>FECHA DE CREACIÓN</Typography>
        </Box>
      ),
      columnValue: (item) =>
        moment(parseInt(item.gmt_created_at || ''))
          .utc(false)
          .format('YYYY-MM-DD HH:mm'),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>NOMBRE</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Box textAlign="left">{item.name as string}</Box>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>URL DE REGISTRO</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Box width="100%" justifyContent="left" display="flex">
          <DashboardPopoverLinkItem
            url={
              item.social_providers?.find((provider) => provider?.primary)
                ?.url_register || ''
            }
          />
        </Box>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>EMAIL</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Box textAlign="left" color={'#BBBBBB'}>
          {item.email as string}
        </Box>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>CANAL</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Typography fontSize={14} fontWeight={700}>
          {item.social_providers?.find((provider) => provider?.primary)
            ?.provider ?? ''}
        </Typography>
      ),
    },
  ];
  const classes = useStylesCustom();

  const users = (data?.users?.items || []) as User[];

  return (
    <>
      <Box
        marginTop={1}
        marginBottom={2}
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        alignItems={'center'}>
        <Typography
          component={'div'}
          sx={{ marginBottom: { xs: '10px', md: '0px' } }}
          fontSize={20}
          fontWeight={700}>
          Usuarios registrados
        </Typography>
        <ButtonDefault
          onClick={() =>
            callReport({
              from,
              to,
            })
          }
          startIcon={
            !loadingReport ? (
              <img src="/icon-clip.svg" alt="clip" width={14} height={14} />
            ) : null
          }
          isLoading={loadingReport}
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
            background: PRIMARY_COLOR ?? 'black',
          }}>
          Descargar Reporte
        </ButtonDefault>
      </Box>
      <Card
        elevation={2}
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}>
        <CardContent className={classes.content}>
          <TableDefaultSticky
            count={total}
            items={users}
            columns={COLUMNS}
            page={page}
            pageSize={pageSize}
            loading={loading}
            messageLoading={'Cargando usuarios registrados...'}
            handleChangePage={(p) => {
              setPagination({
                pageSize,
                page: p + 1,
              });
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            handleChangeRowPerPage={(e) => {
              setPagination({
                pageSize: parseInt(e.target.value, 10),
                page: 1,
              });
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

import { APP_ENV, DATE_FORMAT, DateFilter } from './constants';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  PlanStatuses,
  UserRoles,
} from './types/generated';
import { CountData } from '../modules/dashboard/dashboard-types';
import {
  getCurrentUrl,
  getIsIFrame,
  getPostUrl,
  getRedirectUrl,
} from '../modules/auth/auth-utils';

export const generateDateFilter = (): { from: string; to: string } => {
  return {
    from: momentTimezone().subtract(1, 'day').toISOString(),
    to: momentTimezone().toISOString(),
  };
};

export const getDateByTimezone = (
  date: string | number,
  timezone: string | undefined | null,
): string => {
  const sanitizeDate =
    typeof date === 'string' && !isNaN(Number(date))
      ? parseInt(date as string)
      : date;

  return timezone
    ? momentTimezone(sanitizeDate).tz(timezone).utc(true).format(DATE_FORMAT)
    : momentTimezone(sanitizeDate).format(DATE_FORMAT);
};


export const sanitizeUserRole = (role: UserRoles): string => {
  return role === UserRoles.Administrator ? 'Administrador' : 'Usuario';
};

export const sanitizePlanStatus = (status: PlanStatuses): string => {
  return status === PlanStatuses.Active ? 'ACTIVO' : 'INACTIVO';
};

export const generateCategoryMonth = (
  filter: DateFilter,
  startDate: string,
  endDate: string,
  data: CountData[],
): string[] => {

  const dates = [];
  const from = momentTimezone(startDate).utc(false);
  const to = momentTimezone(endDate).utc(false);
  const currentTime = data.length ? data[data.length - 1].date : '';

  if (filter === DateFilter.TODAY) {
    const diff = moment(to).diff(from, 'hour') + 1;

    for (let i = 0; i < diff; i++) {
      const timeAgo = momentTimezone(from.format('YYYY-MM-DD HH:mm'));
      const date = timeAgo.add(i, 'hour').format('YYYY-MM-DD HH:mm');

      dates.push(date);

      if (date === currentTime) break;
    }

  } else if (filter === DateFilter.YESTERDAY) {

    const diff = moment(to).diff(from, 'hour', true);

    for (let i = 0; i < diff; i++) {
      const timeAgo = momentTimezone(from.format('YYYY-MM-DD HH:mm'));
      const date = timeAgo.add(i, 'hour').format('YYYY-MM-DD HH:mm');
      dates.push(date);
    }

  } else if (filter === DateFilter.MONTH) {

    const diff = moment(to).diff(from, 'month') + 1;

    for (let i = 0; i < diff; i++) {
      const timeAgo = momentTimezone(from.format('YYYY-MM-DD HH:mm'));
      const date = timeAgo.add(i, 'month').format('YYYY-MM');
      dates.push(date);
    }

  } else if (
    [
      DateFilter.CURRENT_WEEK,
      DateFilter.WEEK,
      DateFilter.LAST_WEEK,
      DateFilter.LAST_MONTH,
      DateFilter.CURRENT_MONTH,
      DateFilter.CUSTOM,
      DateFilter.DAY,
    ].includes(filter)
  ) {

    const diff = moment(to).diff(from, 'days', true);

    for (let i = 0; i < diff; i++) {
      const timeAgo = momentTimezone(from.format('YYYY-MM-DD HH:mm'));
      const date = timeAgo.add(i, 'day').format('MM-DD');
      dates.push(date);
    }
  } else if ([DateFilter.YEAR, DateFilter.CURRENT_YEAR].includes(filter)) {
    const diff = moment(to).diff(from, 'month') + 1;

    for (let i = 0; i < diff; i++) {
      const timeAgo = momentTimezone(from.format('YYYY-MM-DD HH:mm'));
      const date = timeAgo.add(i, 'month').format('YYYY-MM');
      dates.push(date);
    }
  }


  return dates;
};

export const formatterDateFilter = (
  from: Date | undefined,
  to: Date | undefined,
  filter: DateFilter,
): {
  startDate: string;
  endDate: string;
} => {
  let startDate = undefined;
  let endDate = undefined;

  if (filter === DateFilter.TODAY) {
    startDate = moment().startOf('day').utcOffset(0).format();
    endDate = moment().endOf('day').utcOffset(0).format();

  } else if (filter === DateFilter.YESTERDAY) {
    startDate = moment()
      .subtract(1, 'day')
      .startOf('day')
      .format();
    endDate = moment().subtract(1, 'day').endOf('day').format();
  } else if (filter === DateFilter.LAST_WEEK) {
    startDate = moment()
      .subtract(1, 'week')
      .startOf('week')
      .format();
    endDate = moment().subtract(1, 'week').endOf('week').format();
  } else if (filter === DateFilter.CURRENT_WEEK) {
    startDate = moment().startOf('week').format();
    endDate = moment();
  } else if (filter === DateFilter.CURRENT_MONTH) {
    startDate = moment().startOf('month').format();
    endDate = moment().format();
  } else if (filter === DateFilter.LAST_MONTH) {
    startDate = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format();
    endDate = moment().subtract(1, 'month')
      .endOf('month')
      .format();
  } else if (filter === DateFilter.CURRENT_YEAR) {

    startDate = moment().startOf('year').format();
    endDate = moment().format();

  } else if (filter === DateFilter.CUSTOM) {

    startDate = moment(from)
      .startOf('day')
      .format();
    endDate = moment(to)
      .endOf('day')
      .format();
  }

  console.log('formatterDateFilter', { startDate, endDate });

  return {
    startDate: startDate as string,
    endDate: endDate as string,
  };
};

export const sanitizeCustomDate = (
  filter: DateFilter,
  filters: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  },
): DateFilter => {
  let dateFilter = filter;

  if (dateFilter === DateFilter.CUSTOM) {
    const diffYears = moment(filters.endDate).diff(
      filters.startDate,
      'years',
      true,
    );
    const diffMonths = moment(filters.endDate).diff(
      filters.startDate,
      'months',
      true,
    );
    const diffDays = moment(filters.endDate).diff(
      filters.startDate,
      'days',
      true,
    );

    if (diffYears >= 1) {
      dateFilter = DateFilter.YEAR;
    } else if (diffMonths > 1 && diffMonths < 12) {
      dateFilter = DateFilter.MONTH;
    } else if (diffDays >= 1 && diffDays < 31) {
      dateFilter = DateFilter.DAY;
    } else {
      dateFilter = DateFilter.TODAY;
    }
  }

  return dateFilter;
};

export const redirectToHome = (route: string): void => {
  const isIframe = getIsIFrame();
  const redirectTo = getRedirectUrl();
  const postTo = getPostUrl();

  if (redirectTo || postTo) {
    window.location.href = redirectTo || postTo;
  } else if (isIframe) {
    window.parent.location = getCurrentUrl() as string;
  } else {
    window.location.href =
      APP_ENV !== 'local' ? 'https://easydata.digitalroom.tech/' : route;
  }
};

export function formatCurrencyMXN(value: number): string {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(value);
}

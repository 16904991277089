import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';

export const PRIMARY_COLOR = '#FE0000'; //rgba(50,50,50,.85)
export const SECONDARY_COLOR = '#FE0000';

export const customTheme = (): Theme => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: '#FE000020',
      },
    },
    //"PT Sans",helvetica,arial,sans-serif
    typography: {
      fontFamily: [
        'Barlow',
        '"PT Sans"',
        'helvetica',
        'arial',
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: '#D5DCE6',
              color: '#fff',
              fontWeight: 'bold',
            },
            '&': {
              border: '1px solid #CCCCCC',
            },
          },
        },
      },
    },
  });
  return responsiveFontSizes(muiTheme);
};

export const theme = customTheme();

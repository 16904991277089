import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import {
  IconButton,
  InputBase,
  Link,
  Paper,
  Box,
  Stack,
  Typography,
  Container,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { useAuth } from '../auth/auth-hook';
import { TableColumnType } from '../../shared/components/table/table-types';
import moment from 'moment';
import { DateRangePickerWithText } from '../../shared/components/DateRangePicker';
import { formatterDateFilter } from '../../shared/utils';
import { DateFilter } from '../../shared/constants';
import { useBreakPoint } from '../../shared/hooks/useBreakPoint';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { useNavigate } from 'react-router-dom';
import {
  useUsersLazyQuery,
  User,
  SortOrder,
  useToggleAdministratorUserRoleMutation,
  UserRoles,
  UserOrderInput,
} from '../../shared/types/generated';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';

export const Users: React.FC = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState<number>(0);
  const { isBelow } = useBreakPoint('sm');
  const { user } = useAuth();
  const [{ from, to }, setDate] = useState({
    from: moment().utc(true).startOf('day').format(),
    to: moment().utc(true).endOf('day').format(),
  });
  const [{ page, pageSize }, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<UserOrderInput>({
    gmt_created_at: SortOrder.Desc,
  });

  const [callUsers, { data, loading, refetch }] = useUsersLazyQuery({
    onCompleted: (d) => setTotal(d?.users?.count || 0),
  });

  const [toogleAdministratorUserRole] = useToggleAdministratorUserRoleMutation({
    onCompleted: () => {
      refetch();
      openSnackbarAction(
        'El rol de administrador ha sido actualizado correctamente',
        TypeMessage.SUCCESS,
      );
    },
    onError: () => {
      openSnackbarAction(
        'Ha ocurrido un error al intentar actualizar el rol de administrador',
        TypeMessage.ERROR,
      );
    },
  });

  const handleUserDetailsClick = (item: User): void => {
    navigate(`/users/${item.id}`);
  };

  useEffect(() => {
    callUsers({
      variables: {
        where: {
          gmt_created_at: {
            lte: to,
            gte: from,
          },
          ...(search !== '' && {
            email: {
              startsWith: search,
            },
          }),
        },
        skip: (page - 1) * pageSize,
        take: pageSize,
        orderBy: sort,
      },
    });
  }, [page, pageSize, callUsers, from, to, search, sort]);

  const COLUMNS: TableColumnType<User>[] = [
    {
      columnName: 'NOMBRE',
      columnValue: null,
      columnComponent: (item) => (
        <Link
          component="button"
          sx={{ color: 'black !important', textDecorationColor: 'black' }}
          onClick={() => handleUserDetailsClick(item)}>
          {item.name || ''}
        </Link>
      ),
      sortableField: 'name'
    },
    {
      columnName: 'EMAIL',
      columnValue: null,
      columnComponent: (item) => <Box>{item.email || ''}</Box>,
      sortableField: 'email'
    },
    {
      columnName: 'PROVEEDOR',
      columnValue: (item) =>
        item.social_providers?.map((p) => p?.provider).join(',') || '',
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item) =>
        moment(parseInt(item.gmt_created_at || ''))
          .utc(false)
          .format('YYYY-MM-DD HH:mm'),
      sortableField: 'gmt_created_at'
    },
  ];

  const users = (data?.users?.items || []) as User[];

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          background: 'white',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width={'100%'}
          alignItems={'center'}
          justifyContent={{ xs: 'space-between' }}>
          <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
            Usuarios
          </Typography>
          <Box sx={{ width: { sx: '100%', md: 'auto' } }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              padding={'10px 0px'}
              spacing={1}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Paper
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 400,
                  height: '30px',
                }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={isBelow ? 'BUSCAR' : 'BUSCAR USUARIO'}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  size="small"
                  onKeyUp={(e) => {
                    if (e.code === 'Enter') {
                      setPagination({
                        pageSize,
                        page: 1,
                      });
                    }
                  }}
                />

                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => {
                    setPagination({
                      pageSize,
                      page: 1,
                    });
                  }}>
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: '0px 0px 8px 0px' }}>
                <DateRangePickerWithText
                  onChange={(range) => {
                    const filters = formatterDateFilter(
                      range.startDate,
                      range.endDate,
                      range.key as DateFilter,
                    );

                    setDate({
                      from: filters.startDate,
                      to: filters.endDate,
                    });
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <CardSection title={''}>
          <TableDefault
            items={users}
            count={total}
            columns={COLUMNS}
            page={page}
            pageSize={pageSize}
            loading={loading}
            messageLoading={'Cargando usuarios...'}
            handleSort={(field, order) => {
              setSort({
                [field]: order,
              });
            }}
            handleChangePage={(p) => {
              setPagination({
                pageSize,
                page: p + 1,
              });
            }}
            showPagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            handleChangeRowPerPage={(e) => {
              setPagination({
                pageSize: parseInt(e.target.value, 10),
                page: 1,
              });
            }}
            actions={[
              {
                actionName: 'Ver detalles',
                onClick: (item) => handleUserDetailsClick(item),
              },
              {
                actionName: (item) => {
                  if (
                    item.roles_on_users?.some(
                      (r) => r?.role?.name === UserRoles.Administrator,
                    )
                  ) {
                    return 'Quitar rol de administrador';
                  } else {
                    return 'Asignar rol de administrador';
                  }
                },
                onClick: (item) => {
                  toogleAdministratorUserRole({
                    variables: {
                      data: {
                        user_id: item.id,
                      },
                    },
                  });
                  setPagination({
                    pageSize,
                    page: 1,
                  });
                },
              },
            ]}
          />
        </CardSection>
      </Container>
    </>
  );
};
